<template>
  <modal :show="show" @close="$emit('close')" title="Detail Peserta">
    <div class="text-sm mb-4">
      Peserta berikut sudah pernah terdaftar sebelumnya sehingga tidak masuk
      kedalam perhitungan peserta
    </div>

    <table class="w-full border table-auto text-sm">
      <thead class="border-b text-blue-900 bg-blue-50">
        <tr>
          <th class="px-2 py-3">Email Peserta</th>
          <th>Nama Asesmen</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(excludedItem, excludedItemIdx) in data"
          :key="excludedItemIdx"
        >
          <td class="p-2">{{ excludedItem.email }}</td>
          <td>{{ excludedItem.bundle }}</td>
        </tr>
      </tbody>
    </table>
    <template #footer>
      <button
        @click="$emit('close')"
        class="
          px-4
          py-2
          bg-yellow-400
          border-1 border-yellow-400
          rounded
          text-white
          leading-6
          text-sm
          font-semibold
        "
      >
        Tutup
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/atoms/Modal.atoms.vue";

export default {
  components: { Modal },
  props: {
    show: Boolean,
    data: Array,
  },
  emits: ["close"],
};
</script>
