
import { AxiosError } from "axios";
import { defineComponent, inject, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

// services
import projectService from "@/services/project.service";

// interfaces
import { IInvitationSummary, ITestSummary } from "@/interfaces/invitation";

// components
import InvitationSummary from "@/components/molecules/InvitationSummary.molecules.vue";
import ConfirmExcludedParticipantModal from "@/components/molecules/ConfirmExcludedParticipantModal.molecules.vue";
import BackButton from "@/components/atoms/BackButton.vue";

import useDateFormatter from "@/composables/dateFormatter";

export default defineComponent({
  components: {
    InvitationSummary,
    ConfirmExcludedParticipantModal,
    BackButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();
    const queries = route.query;

    const getProfile = inject("getProfile") as CallableFunction;

    const state = reactive({ data: {} as IInvitationSummary });
    const projectId = Number(route.params.projectId);

    const reqBuilder = () => {
      const req = {
        emails: Array.isArray(queries.emails)
          ? queries.emails
          : [queries.emails],
        ended_at: queries.ended_at,
        bundle_id: Array.isArray(queries.bundle_id)
          ? (queries.bundle_id as Array<string>).map((d: string) => +d)
          : [Number(queries.bundle_id)],
      };

      return req;
    };

    onMounted(async () => {
      const req = reqBuilder();
      const { formatDateTime } = useDateFormatter();
      try {
        const { data } = await projectService.validateProject(projectId, req);
        state.data = {
          project_id: projectId,
          total_participants: data.total_participants,
          total_tests: data.total_bundle,
          ended_at: data.ended_at,
          tests: data.bundles,
          total_token: data.total_token,
          total_excluded: data.total_excluded,
          excluded: data.excluded,
          project: {
            name: data.project.name,
            started_at: formatDateTime(
              data.project.started_at,
              "DD MMMM YYYY [00:00]"
            ),
            ended_at: formatDateTime(
              data.project.ended_at,
              "DD MMMM YYYY [23:59]"
            ),
          },
        } as IInvitationSummary;
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
        router.push({ path: `/admin/project/${projectId}/invite`, query: req });
      }
    });

    // pay handler
    const payInvitation = async () => {
      const req = reqBuilder();
      try {
        const { data } = await projectService.inviteParticipants(
          projectId,
          req
        );
        toast.success("Invitation berhasil");

        await getProfile();
        const reqQueries = {
          project_id: data.project.id,
          total_participants: data.total_participants,
          total_tests: data.total_bundles,
          ended_at: data.ended_at,
          total_token: data.total_token,
          status: data.token_history.status,
          invoice_number: data.token_history.invoice_number,
          created_at: data.token_history.created_at,
          tests: [],
        };

        reqQueries.tests = data.bundles.map((d: ITestSummary) =>
          JSON.stringify({
            id: d.id,
            name: d.name,
            image: d.image,
            token: d.token,
            sub_total_token: d.sub_total_token,
          })
        );

        router.push({
          path: `/admin/project/invite/invoice`,
          query: reqQueries,
        });
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      }
    };

    // modal participant
    const showModalParticipant = ref(false);
    const setShowModalParticipant = (val: boolean) => {
      showModalParticipant.value = val;
    };

    return {
      state,
      payInvitation,
      showModalParticipant,
      setShowModalParticipant,
    };
  },
});
