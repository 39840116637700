<template>
  <div>
    <div
      v-if="show"
      class="
        overflow-x-hidden overflow-y-auto
        fixed
        inset-0
        z-50
        outline-none
        focus:outline-none
        justify-center
        items-center
      "
    >
      <div class="relative w-auto my-6 mx-auto max-w-xl">
        <!--content-->
        <div
          class="
            rounded-lg
            shadow-lg
            relative
            flex flex-col
            w-full
            bg-white
            outline-none
            focus:outline-none
          "
        >
          <!--header-->
          <div
            class="
              flex
              items-center
              justify-between
              py-3
              px-6
              border-b
              rounded-t
            "
          >
            <div
              class="
                text-xl
                leading-7
                font-semibold
                tracking-wider
                text-blue-900
              "
            >
              {{ title }}
            </div>
            <img
              @click="$emit('close')"
              class="cursor-pointer"
              src="@/assets/img/icons/clear.svg"
            />
          </div>
          <!--body-->
          <div class="relative px-6 py-3">
            <slot />
          </div>
          <!--footer-->
          <div
            class="flex items-center justify-end py-3 px-8 border-t rounded-b"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="show" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
  },
  emits: ["close"],
};
</script>
